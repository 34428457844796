body {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed !important;
}

body::-webkit-scrollbar {
  display: none;
}
.title-home {
  margin-bottom: 2.5%;
  font-weight: bold;
}
.desc-home {
  color: white;
  text-align: center;
}
.a-tag-without-link {
  cursor: pointer;
}
.modal {
  display: block !important; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
.modal-2 {
  display: block !important; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: forestgreen !important;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 50% !important; /* Could be more or less, depending on screen size */
  height: 20% !important;
}
.modal-content-2 {
  background-color: transparent !important;
  margin: 5% auto; /* 15% from the top and centered */
  padding: 20px;
  width: 50% !important; /* Could be more or less, depending on screen size */
  height: 20% !important;
}
.modal-content-2 > img {
  width: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.modal-content > p {
  font-weight: bold;
  font-size: 20pt;
  color: white;
}
.modal-content-2 > span {
  position: relative;
  left: 97%;
  top: 25%;
  font-weight: bold;
  font-size: 20pt;
  color: white;
  z-index: 2000;
  cursor: pointer;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.carousel-home {
  /* position: relative; */
  width: 60%;
  margin-left: 20%;
  margin-bottom: 5% !important;
}
.img-home {
  width: 500px;
  height: 500px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.title-futbol {
  font-size: 25pt !important;
  font-weight: bold !important;
}
.map-responsive {
  overflow: hidden;

  padding-bottom: 56.25%;

  position: relative;

  height: 0;
}

.map-responsive iframe {
  left: 0;

  top: 0;

  height: 100%;

  width: 100%;

  position: absolute;
}
.conatcs-form {
  background-color: rgb(32, 62, 74);
  border-radius: 15pt;
}
.working-time-title {
  color: orange;
  font-size: 20pt;
  font-weight: bold;
}

.working-time {
  color: white;
  font-size: 30pt;
}
.working-time > a {
  color: white;
  font-size: 30pt;
}

.container-custom {
  margin: 7.5%;
}

.menu-admin {
  margin-bottom: 2.5%;
}

.menu-admin > button {
  background-color: rgb(185, 58, 50) !important;
}

.navbar-brand > img {
  width: 5%;
  margin-left: 5%;
}

.tournaments-container {
  text-align: center;
  margin-top: 2.5%;
}

.tournaments-container > div > span {
  color: white;
  font-size: 25pt;
}

.name-tournaments {
  text-align: center;
  margin-bottom: 2.5%;
}

.name-tournaments > div {
  text-align: center;
  margin-bottom: 2.5%;
}

.name-tournaments > div > span {
  color: white;
  font-size: 25pt;
}

/*Navbar*/

.navbar {
  position: fixed !important;
  /* Set the navbar to fixed position */
  top: 0;
  /* Position the navbar at the top of the page */
  width: 100%;
  /* Full width */
  z-index: 1;
  background-color: rgb(15, 15, 15);
}

.page-title {
  opacity: 0.75 !important;
}

.containerAddData {
  text-align: center !important;
  margin-left: 5%;
  margin-right: 7.5%;
}

.goals-container {
  padding: 0 !important;
}

.label-teams {
  color: white;
  margin-right: 2%;
}

.vs-label {
  color: white;
  font-size: 25pt;
  margin-top: 25px;
}

.ball-img {
  width: 50%;
  margin-top: 25px;
}

.played-match-title > span {
  color: white;
  font-size: 35pt;
}

.admin-container > div {
  margin-bottom: 2.5%;
}

.played-match-container > div {
  margin-bottom: 1%;
}

.played-match-container {
  border: solid;
  border-color: white;
  border-width: 2px;
  box-shadow: 1px 1px 10px 5px white;
}

.match-play {
  margin-bottom: 2.5%;
  text-align: center;
  width: 100%;
  border: solid;
  border-width: 2.5px;
  border-color: white;
  padding-bottom: 1%;
}

.name-team > input {
  width: 50%;
}

.add-tournament > .save-info {
  margin-top: 1%;
}

.save-info {
  text-align: center;
  color: white;
  margin-left: 47.5%;
  margin-right: 47.5%;
  border-radius: 15pt;
  background-color: green;
  cursor: pointer;
}

.save-info:hover {
  background-color: forestgreen;
}

.text-intro {
  color: white;
  font-size: 15pt;
}

.tournaments-container > div {
  color: white;
}

/* .custom-nav-drujba {
  background: transparent !important;
  z-index: 1000;
} */

.carousel {
  margin-top: 0%;
}

.carousel > div > div > div > div > div > figure > img {
  max-height: 200px !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.menu-tournaments {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: auto;
  text-align: center;
}

.links-tournaments {
  width: auto;
  height: auto;
  border-radius: 5px;
  background-color: rgb(136, 177, 75);
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-left: 5px;
  cursor: pointer;
}

.links-tournaments:hover {
  color: wheat;
  text-decoration: none;
}

.kids-tournaments {
  text-align: center;
}

.KidsLinks-tournaments {
  display: block;
  flex-direction: row;
  font-size: 20px;
  padding: 10px;
  color: black;
  background: skyblue;
  width: 15%;
  border-radius: 5px;
  margin-top: 5px;
}

.KidsLinks-tournaments:hover {
  text-decoration: none;
  color: wheat;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 780px) {
  .modal-2 {
    display: block !important; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  }

  /* Modal Content/Box */
  .modal-content-2 {
    background-color: transparent !important;
    margin: 5% auto; /* 15% from the top and centered */
    padding: 20px;
    width: 100% !important; /* Could be more or less, depending on screen size */
    height: 20% !important;
  }
  .modal-content-2 > img {
    width: 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .modal-content-2 > span {
    position: relative;
    left: 92.5%;
    top: 27.5%;
    font-weight: bold;
    font-size: 20pt;
    color: white;
    z-index: 2000;
    cursor: pointer;
  }
  .img-home {
    width: 500px;
    height: 200px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .modal-content {
    background-color: forestgreen !important;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 100% !important; /* Could be more or less, depending on screen size */
    height: 20% !important;
  }
  .title-home {
    margin-bottom: 7.5%;
  }
  .carousel-home {
    /* position: relative; */
    width: 100%;
    margin-left: 0%;
    margin-bottom: 15% !important;
  }
  .menu-tournaments {
    flex-direction: column;
    width: 100%;
    text-align: center;
  }
  .links-tournaments {
    flex-direction: column;
    width: 100%;
    text-align: center;
    margin-top: 5px;
    background: green;
    color: white;
    border-radius: 5px;
    padding: 15px 15px;
    margin-right: 5px;
    font-size: 15px;
  }
  .KidsLinks-tournaments {
    display: block;
    flex-direction: row;
    font-size: 20px;
    padding: 10px;
    color: black;
    background: skyblue;
    width: 100%;
    border-radius: 5px;
    margin-top: 5px;
  }
  .admin-container > div {
    margin-bottom: 12.5%;
  }
  .played-match-container > div {
    margin-bottom: 5%;
  }
  .navbar-brand > img {
    position: relative;
    width: 15%;
    top: 25%;
  }
  .navbar-brand > button {
    position: relative;
    left: 70%;
  }
  .container-custom {
    margin-top: 20.5%;
  }
  th {
    padding: 20px 15px;
    text-align: center;
    font-weight: 500;
    font-size: 10px;
    color: #fff;
    text-transform: uppercase;
  }
  td {
    font-family: Arial, Helvetica, sans-serif;
    background-color: rgb(32, 62, 74);
    padding: 15px;
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
    font-size: 10px !important;
    color: #fff;
    border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  }
  .result-a {
    padding-left: 20% !important;
  }
  .result-b {
    padding-left: 0% !important;
  }
  .loader {
    position: fixed;
    z-index: 100;
    left: 18% !important;
    top: 50%;
    width: 100%;
    height: 100%;
    z-index: 9999;
  }
  .menu-admin {
    margin-bottom: 15%;
  }
  .tournaments-class {
    margin-bottom: 5% !important;
  }
  .teams-class {
    margin-bottom: 5% !important;
  }
  tr > th {
    font-size: 5pt;
  }

  tr > td {
    font-size: 5pt !important;
  }
  .classification-header {
    position: relative;
    right: 15px;
  }
}

.menu-admin > .active {
  background-color: rgb(0, 163, 224) !important;
}
.menu-tournaments > .active {
  background-color: rgb(220, 121, 58);
}
.tournaments-container > div {
  margin-bottom: 2.5%;
}

.result-a {
  padding-left: 15%;
}

.result-b {
  padding-right: 25% !important;
}

.teams-class {
  padding: 2.5%;
  border-radius: 25pt;
  background-color: rgb(32, 62, 74);
  margin: 1.5%;
  box-shadow: 1px 1px 0px 5px rgb(220, 121, 58);
  text-transform: uppercase;
  font-weight: bolder;
}

.tournaments-class {
  padding: 2.5%;
  border-radius: 25pt;
  background-color: rgb(32, 62, 74);
  margin: 1.5%;
  box-shadow: 1px 1px 0px 5px rgb(220, 121, 58);
  text-transform: uppercase;
  font-weight: bolder;
  cursor: pointer;
  color: white;
}

.button-arrow {
  background-color: rgb(0, 163, 224) !important;
  font-weight: bolder !important;
}

.neutral-color {
  color: rgb(0, 163, 224);
}

.win-color {
  color: rgb(136, 177, 75);
}

.lost-color {
  color: rgb(185, 58, 50);
}

.draw-color {
  color: rgb(220, 121, 58);
}

.img-ball-results > img {
  width: 25%;
}

h1 {
  font-size: 30px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 300;
  text-align: center;
  margin-bottom: 15px;
}

table {
  width: 100%;
  table-layout: fixed;
}

.table-results {
  padding-left: 25% !important;
}

.tbl-header {
  background-color: rgba(255, 255, 255, 0.3);
}

.tbl-content {
  overflow-x: auto;
  margin-top: 0px;
}

th {
  padding: 20px 15px;
  text-align: center;
  font-weight: 500;
  font-size: 15px;
  color: #fff;
  text-transform: uppercase;
  background-color: rgb(37, 132, 170);
}

td {
  font-family: Arial, Helvetica, sans-serif;
  background-color: rgb(32, 62, 74);
  padding: 15px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  font-size: 15px;
  color: #fff;
  border-bottom: 1px solid white;
}

/* demo styles */

section {
  margin: 50px;
}

/* follow me template */

.made-with-love {
  margin-top: 40px;
  padding: 10px;
  clear: left;
  text-align: center;
  font-size: 10px;
  font-family: arial;
  color: #fff;
}

.made-with-love i {
  font-style: normal;
  color: #f50057;
  font-size: 14px;
  position: relative;
  top: 2px;
}

.made-with-love a {
  color: #fff;
  text-decoration: none;
}

.made-with-love a:hover {
  text-decoration: underline;
}

/* for custom scrollbar for webkit browser*/

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.loader {
  position: fixed;
  z-index: 100;
  left: 35%;
  top: 25%;
  width: 100%;
  height: 100%;
  z-index: 9999;
}
.input-goals-modify {
  width: 30px;
}
.remove-new {
  cursor: pointer;
  z-index: 10000;
}

.classification-content > table {
  table-layout: auto !important;
  border-collapse: collapse;
  border-spacing: 0;
}
.classification-header > table {
  table-layout: auto !important;
  border-collapse: collapse;
  border-spacing: 0;
}
/* .classification-content > table > tbody > tr > td {
}
.classification-header > table > tr > th {
} */
/* .pos {
  position: relative;
  right: 2.5%;
} */
.official-loss {
  position: relative;
  top: 50%;
}
.official-loss > label {
  color: white;
}
.input-first-team {
  color: black;
}

.navbar-nav > li > a:hover {
  color: rgb(0, 163, 224) !important;
  font-weight: bolder;
}

.img-uploaded {
  width: 100%;
  height: 100%;
}

.not-found-pic {
  color: white;
  font-size: 15pt;
  font-weight: bolder;
}

.active a {
  color: rgb(0, 163, 224) !important;
  font-weight: bolder;
}

.box {
  width: 300px;
  padding: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgb(12, 76, 138) !important;
  text-align: center;
  border-radius: 25pt;
  box-shadow: 0px 0px 5px 2.5px white;
}

.box input[type="text"],
.box input[type="password"] {
  border: 0;
  background: none;
  display: block;
  margin: 20px auto;
  text-align: center;
  border: 2px solid white;
  padding: 14px 10px;
  width: 200px;
  outline: none;
  color: white;
  border-radius: 24px;
  transition: 0.25s;
}

.box input[type="text"]:focus,
.box input[type="password"]:focus {
  width: 220px;
  border-color: cadetblue;
}

.box button {
  border: 0;
  background: none;
  display: block;
  margin: 20px auto;
  text-align: center;
  border: 2px solid white;
  padding: 14px 40px;
  outline: none;
  color: white;
  border-radius: 24px;
  transition: 0.25s;
  cursor: pointer;
}

.box input[type="submit"]:hover {
  background: rgb(0, 163, 224) !important;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border: none;
  background-color: rgb(32, 62, 74) !important;
}

.card * {
  color: white;
}

.card-text * {
  color: white !important;
}

.text-muted i {
  margin: 0 10px;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.4);
}

.title-teren {
  font-weight: bold !important;
}

.card a {
  color: initial;
}

/* .card-img-top {
  width: 95% !important;
  margin-top: 1%;
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56),
    0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
} */

.card a:hover {
  text-decoration: initial;
}

.pricing-table {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  background: #ffffff;
}

.pricing-table .ptable-item {
  width: 25%;
  padding: 0 10px;
  margin-bottom: 30px;
}

@media (max-width: 992px) {
  .pricing-table .ptable-item {
    width: 33.33%;
  }
}

@media (max-width: 768px) {
  .pricing-table .ptable-item {
    width: 50%;
  }
}

@media (max-width: 576px) {
  .pricing-table .ptable-item {
    width: 100%;
  }
}

.pricing-table .ptable-single {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.pricing-table {
  background-color: transparent;
}

.pricing-table .ptable-header,
.pricing-table .ptable-body,
.pricing-table .ptable-footer {
  position: relative;
  width: 100%;
  text-align: center;
  overflow: hidden;
}

.pricing-table .ptable-status,
.pricing-table .ptable-title,
.pricing-table .ptable-price,
.pricing-table .ptable-description,
.pricing-table .ptable-action {
  position: relative;
  width: 100%;
  text-align: center;
}

.pricing-table .ptable-single {
  background: transparent;
}

/* .pricing-table .ptable-single:hover {
  box-shadow: 0 0 10px #999999;
} */

.pricing-table .ptable-header {
  margin: 0 30px;
  padding: 30px 0 45px 0;
  width: auto;
  background: #2a293e;
}

.pricing-table .ptable-header::before,
.pricing-table .ptable-header::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 0;
  height: 0;
  border-bottom: 100px solid #f6f8fa;
}

.pricing-table .ptable-header::before {
  right: 50%;
  border-right: 250px solid transparent;
}

.ptable-description > img {
  width: 100%;
}

.pricing-table .ptable-header::after {
  left: 50%;
  border-left: 250px solid transparent;
}

.pricing-table .ptable-item.featured-item .ptable-header {
  background: #ff6f61;
}

.pricing-table .ptable-status {
  margin-top: -30px;
}

.pricing-table .ptable-status span {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 30px;
  padding: 5px 0;
  text-align: center;
  color: #ff6f61;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 1px;
  background: #2a293e;
}

.pricing-table .ptable-status span::before,
.pricing-table .ptable-status span::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 0;
  height: 0;
  border-bottom: 10px solid #ff6f61;
}

.pricing-table .ptable-status span::before {
  right: 50%;
  border-right: 25px solid transparent;
}

.pricing-table .ptable-status span::after {
  left: 50%;
  border-left: 25px solid transparent;
}

.pricing-table .ptable-title h2 {
  color: #ffffff;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 2px;
}

.pricing-table .ptable-price h2 {
  margin: 0;
  color: #ffffff;
  font-size: 45px;
  font-weight: 700;
  margin-left: 15px;
}

.pricing-table .ptable-price h2 small {
  position: absolute;
  font-size: 18px;
  font-weight: 300;
  margin-top: 16px;
  margin-left: -15px;
}

.pricing-table .ptable-price h2 span {
  margin-left: 3px;
  font-size: 16px;
  font-weight: 300;
}

.pricing-table .ptable-body {
  padding: 20px 0;
}

.pricing-table .ptable-description ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.pricing-table .ptable-description ul li {
  color: #2a293e;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 1px;
  padding: 7px;
  border-bottom: 1px solid #dedede;
}

.pricing-table .ptable-description ul li:last-child {
  border: none;
}

.pricing-table .ptable-footer {
  padding-bottom: 30px;
}

.pricing-table .ptable-action a {
  display: inline-block;
  padding: 10px 20px;
  color: #ff6f61;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 2px;
  text-decoration: none;
  background: #2a293e;
}

.pricing-table .ptable-action a:hover {
  color: #2a293e;
  background: #ff6f61;
}

.pricing-table .ptable-item.featured-item .ptable-action a {
  color: #2a293e;
  background: #ff6f61;
}

.pricing-table .ptable-item.featured-item .ptable-action a:hover {
  color: #ff6f61;
  background: #2a293e;
}
